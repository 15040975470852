import {Component, Input, OnInit} from '@angular/core';
import {Quest} from '../../../shared/models/quest.model';
import {QuestService} from '../../../shared/services/quest.service';
import {NgClass, NgForOf, NgIf} from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-quest',
  templateUrl: './quest.component.html',
  imports: [
    NgClass,
    NgIf,
    NgForOf
  ],
  styleUrls: ['./quest.component.scss']
})
export class QuestComponent implements OnInit {

  @Input('quest')
  quest: Quest;
  @Input('optionsDisabled')
  optionsDisabled = false;
  selectedAnswer = -1;
  progress = 0;
  targetTime: Date;
  countdown = 0;


  constructor(private questService: QuestService) {
  }

  ngOnInit() {
    this.targetTime = new Date();
    this.targetTime.setTime(this.targetTime.getTime() + this.quest.timeout * 1000);
    this.startCountdown();
  }

  selectAnswer(id: number) {
    if (!this.optionsDisabled) {
      this.questService.emitAnswer(id).subscribe(value => {
        this.selectedAnswer = id;
      }, error => {
      });
    }
  }

  startCountdown() {
    const now = new Date();
    const timeDiff = this.targetTime.getTime() - now.getTime();
    const diff = this.quest.timeout * 1000 - timeDiff;
    if (diff < 0) {
      return;
    }
    this.progress = diff / ( this.quest.timeout * 1000 );
    this.countdown = Math.round(timeDiff / 1000);
    setTimeout(() => {
      this.startCountdown();
    }, 100);
  }
}
