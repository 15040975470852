import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as io from 'socket.io-client';
import {Quest} from '../models/quest.model';
import {environment} from '../../../environments/environment';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root'
})
export class QuestService {

  socket: SocketIOClient.Socket;
  onNewQuest$: Observable<Quest>;
  onQuestResult$: Observable<any>;
  onWait$: Observable<any>;

  constructor(private tokenService: TokenService) {
  }

  init(): void {
    if (this.socket) {
      return;
    }
    this.socket = io(environment.apiUrl, {
      path: environment.path
    });

    this.onNewQuest$ = new Observable(subscriber => {
      this.socket.on('new-quest', (quest: Quest) => {
        console.log('new-quest');
        subscriber.next(quest);
      });
    });

    this.onQuestResult$ = new Observable(subscriber => {
      this.socket.on('quest-results', results => {
        console.log('quest-results');
        subscriber.next(results);
      });
    });

    this.onWait$ = new Observable(subscriber => {
      this.socket.on('wait', results => {
        console.log('wait');
        subscriber.next(null);
      });
    });
  }

  emitNewQuest(quest: Quest): Observable<Quest> {
    return new Observable(subscriber => {
      this.socket.emit('new-quest', quest, (data) => {
        if (data === quest) {
          subscriber.next(data);
        } else {
          subscriber.error(data);
        }
      });
    });
  }

  emitAnswer(answerId: number) {
    return new Observable(subscriber => {
      this.socket.emit('quest-answer', {token: this.tokenService.token, answer: answerId}, (data) => {
        if (data.answer === answerId) {
          subscriber.next(data);
        } else {
          subscriber.error(data);
        }
      });
    });
  }

  stopQuest(): Observable<any> {
    return new Observable(subscriber => {
      this.socket.emit('stop-quest', null, (data) => {
        if (data) {

        }
      });
    });
  }
}
