import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  token: string;

  constructor(private http: HttpClient) {
  }

  hasToken(): boolean {
    return !!this.token;
  }

  setToken(token: string, captchaToken: string): Observable<boolean> {
    return new Observable(subscriber => {
      this.http.post(environment.httpApiUrl + '/token', {token, captchaToken}).subscribe((value: string) => {
        if (value) {
          this.token = token;
          subscriber.next(true);
        } else {
          subscriber.next(false);
        }
      }, error1 => subscriber.error(error1));
    });
  }


}
