import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import jsQR from 'jsqr';
import {TokenService} from '../../shared/services/token.service';
import {HttpErrorResponse} from '@angular/common/http';
import {RecaptchaComponent, RecaptchaModule} from 'ng-recaptcha';
import {FormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: './login.component.html',
  imports: [
    RecaptchaModule,
    FormsModule,
    NgIf
  ],
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('captcha')
  captcha: RecaptchaComponent;

  token: string;
  captchaToken: string = null;
  showVideo = false;

  isLoading = false;
  loadingMessage: string;
  loginError: string = null;

  @ViewChild('video') video: ElementRef;
  @ViewChild('canvasElement') canvasElement: ElementRef;
  canvas;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private tokenService: TokenService) {
  }

  static parseTokenFromQRData(qrData: string): string {
    return qrData.replace('https://mv.vit-connect.de/login/', '');
  }

  ngOnInit() {
    this.token = this.activeRoute.snapshot.queryParamMap.get('token');
    /* if (this.token) {
      this.login();
    }*/
  }

  scanQRCode() {
    this.showVideo = true;
    navigator.mediaDevices.getUserMedia({video: {facingMode: 'environment'}}).then((stream) => {
      this.video.nativeElement.srcObject = stream;
      this.startVideo();
    }).catch(reason => {
      console.log(reason);
      this.scanQRCode();
    });
  }

  startVideo() {
    try {
      this.video.nativeElement.play().catch(() => {
        this.startVideo();
      }).then(() => requestAnimationFrame(() => this.videoTick()));
    } catch (e) {
      console.log('Error');
      console.log(e);

    }
  }

  videoTick() {
    this.canvas = this.canvasElement.nativeElement.getContext('2d');
    this.loadingMessage = '⌛ Loading video...';
    if (this.video.nativeElement.readyState === this.video.nativeElement.HAVE_ENOUGH_DATA) {
      this.loadingMessage = null;
      // canvasElement.hidden = false;
      // outputContainer.hidden = false;

      this.canvasElement.nativeElement.height = this.video.nativeElement.videoHeight;
      this.canvasElement.nativeElement.width = this.video.nativeElement.videoWidth;
      this.canvas.drawImage(this.video.nativeElement, 0, 0, this.canvasElement.nativeElement.width,
        this.canvasElement.nativeElement.height);
      const imageData = this.canvas.getImageData(0, 0, this.canvasElement.nativeElement.width, this.canvasElement.nativeElement.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'dontInvert',
      });
      if (code) {
        this.token = LoginComponent.parseTokenFromQRData(code.data);
        this.showVideo = false;
        this.video.nativeElement.srcObject.getTracks()[0].stop();
        this.login();
        return;
      } else {
      }
    }
    requestAnimationFrame(() => this.videoTick());
  }

  login() {
    this.loginError = null;
    this.isLoading = true;
    this.tokenService.setToken(this.token, this.captchaToken).subscribe(value => {
      this.isLoading = false;
      if (value) {
        this.router.navigateByUrl('/');
      } /*else {
        this.captcha.reset();
        this.loginError = 'Die Zugangsdaten sind fehlerhaft.';
      }*/
    }, (error1: HttpErrorResponse) => {
      this.isLoading = false;
      this.captcha.reset();
      this.captchaToken = null;
      if (error1.status === 401) {
        this.loginError = 'Die Zugangsdaten sind fehlerhaft.';
      } else {
        this.loginError = 'Error ' + error1.status + ': ' + error1.statusText;
        if (error1.error?.message) {
          this.loginError += ' (' + error1.error.message + ')';
        }
      }

      console.error(error1);
    });
  }

  resolved(event) {
    if (event) {
      this.captchaToken = event;
    }
  }

}
