<div class="vote-quest mt-3">
  <p class="font-weight-bold">Abstimmung</p>
  <p>{{quest.text}}</p>
</div>

<div class="progress">
  <div class="progress-bar" role="progressbar" [style.width]="progress*100+'%'" [attr.aria-valuenow]="progress*100" aria-valuemin="0" aria-valuemax="100">{{countdown}}s</div>
</div>

<div class="quest-answers mt-3 d-flex justify-content-center" *ngIf="quest.options">
  <div class="vote-card d-flex align-items-center justify-content-center font-weight-bold" *ngFor="let option of quest.options" [ngClass]="selectedAnswer==option.id?'bg-success':''" (click)="selectAnswer(option.id)">
    {{option.text}}
  </div>
</div>

<button class="btn" [ngClass]="selectedAnswer==-1?'btn-link':'btn-link'" (click)="selectAnswer(-1)" *ngIf="!optionsDisabled">Enthalten</button>
