import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './abstimmung/dashboard/dashboard.component';
import {LoginComponent} from './abstimmung/login/login.component';
import {AdminDashboardComponent} from './admin/admin-dashboard/admin-dashboard.component';
import {AuthGuardService} from './auth.guard';

const routes: Routes = [
  {path: '', component: DashboardComponent, canActivate: [AuthGuardService]},
  {path: 'login', component: LoginComponent},
  {path: 'login/:token', component: LoginComponent},

  // Admin
  {path: 'admin', component: AdminDashboardComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
