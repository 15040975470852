import {Component, OnInit} from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-quest-waiting',
  templateUrl: './quest-waiting.component.html',
  styleUrls: ['./quest-waiting.component.scss']
})
export class QuestWaitingComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
