<div class="container mt-3">
  <h1 class="text-center"><strong>VIT Connect e.V.</strong> Mitgliederversammlung</h1>
  <div class="quest" *ngIf="mode == 'addquest'">
    <form novalidate>
      <div class="form-group">
        <label for="quest">Frage:</label>
        <input id="quest" type="text" name="quest" [(ngModel)]="quest.text" class="form-control">
      </div>
      <div class="form-group">
        <label for="timeout">Timeout in Sekunden:</label>
        <input id="timeout" type="number" name="timeout" [(ngModel)]="quest.timeout" class="form-control">
      </div>
      <div class="form-group">
        <label for="quest">Antwortoptionen:</label>
        <div>
          <div class="input-group my-1" *ngFor="let option of quest.options; let i = index" data-index="i">
            <input type="text" class="form-control" name="answer-{{i}}" [(ngModel)]="option.text">
            <div class="input-group-append">
              <button class="btn btn-secondary" tabindex="-1" (click)="removeAnswer(i)"><i class="fas fa-times"></i></button>
            </div>
          </div>
          <button class="btn btn-link" (click)="addQuestion()">Option hinzufügen</button>
        </div>
      </div>
      <button class="btn btn-primary" (click)="start()">Start</button>
      <button class="btn btn-secondary" (click)="stop()">Stop</button>
    </form>
  </div>
  <div *ngIf="mode == 'quest'" class="text-center">
    <app-quest [quest]="quest" [optionsDisabled]="true"></app-quest>
    <button class="btn btn-primary" type="button" (click)="stop()">Abbrechen</button>
  </div>
  <div *ngIf="mode == 'results'" class="text-center">
    <app-quest-result [result]="result" [quest]="quest" *ngIf="result"></app-quest-result>
    <button class="btn btn-primary" type="button" (click)="stop()">Weiter</button>
  </div>
</div>
