export class Quest {
  id: number;
  text: string;
  timeout: number;
  options: QuestOption[];
}

export class QuestOption {
  id: number;
  text: string;
}
