import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AuthGuardService } from './app/auth.guard';
import {provideHttpClient} from '@angular/common/http';
import {provideCharts, withDefaultRegisterables} from 'ng2-charts';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule, ReactiveFormsModule, AppRoutingModule),
      provideCharts(withDefaultRegisterables()),
      provideHttpClient(),
        AuthGuardService
    ]
})
  .catch(err => console.error(err));
