import {Component, OnInit} from '@angular/core';
import {QuestService} from '../../shared/services/quest.service';
import {Quest} from '../../shared/models/quest.model';
import {Router} from '@angular/router';
import {NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {QuestResultComponent} from './quest-result/quest-result.component';
import {QuestWaitingComponent} from './quest-waiting/quest-waiting.component';
import {QuestComponent} from './quest/quest.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    NgSwitch,
    NgIf,
    NgSwitchCase,
    QuestResultComponent,
    QuestWaitingComponent,
    QuestComponent
  ]
})
export class DashboardComponent implements OnInit {

  mode: string;
  quest: Quest;
  result;
  error;

  constructor(private questService: QuestService, private router: Router) {
  }

  ngOnInit() {
    /*if (!this.tokenService.hasToken()) {
      this.router.navigateByUrl('login');
      return;
    }*/
    this.questService.init();
    this.mode = 'wait';
    this.questService.onNewQuest$.subscribe((quest: Quest) => {
      this.quest = quest;
      this.mode = 'quest';
      // Standardgemäß Enthaltung senden
      this.questService.emitAnswer(-1).subscribe();
    });
    this.questService.onQuestResult$.subscribe((res) => {
      this.mode = 'result';
      this.result = res.results;
      this.quest = res.quest;
    });
    this.questService.onWait$.subscribe(() => {
      this.mode = 'wait';
      this.result = null;
      this.quest = null;
    });
    this.questService.socket.on('connect', () => {
      this.error = null;
    });

    this.questService.socket.on('connect_error', (error) => {
      this.error = error;
    });
  }

}
