import {Component, Input, OnInit} from '@angular/core';
import {Quest} from '../../../shared/models/quest.model';
import {ChartData, ChartOptions, ChartType} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {NgIf} from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-quest-result',
  templateUrl: './quest-result.component.html',
  imports: [
    NgIf,
    BaseChartDirective
  ],
  styleUrls: ['./quest-result.component.scss']
})
export class QuestResultComponent implements OnInit {

  @Input()
  quest: Quest;

  @Input()
  result;

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public options: ChartOptions = {
  };
  public pieChartData: ChartData = {
    labels: [],
    datasets: [],
  };
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;

  constructor() {
  }

  ngOnInit() {
    this.pieChartData.datasets = [{data: this.result.map(r => r.votes)}];
    this.result.forEach(res => {
      this.pieChartData.labels.push(res.text + ' (' + res.votes + ')');
    });
  }

}
