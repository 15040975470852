<div class="container pt-5">
  <form class="form-signin" (submit)="login()" #form="ngForm">
    <h1 class="h3 mb-3 font-weight-normal">Login</h1>
    <p>Geben Sie Ihren Zugangstoken ein.</p>
    <label for="inputToken" class="sr-only">Zugangstoken</label>
    <div class="input-group">
      <input type="text" id="inputToken" name="token" class="form-control" placeholder="Zugangstoken" autocomplete="off" [(ngModel)]="token" [min]="10" required autofocus>
      <!--div class="input-group-append">
        <button type="button" class="btn btn-secondary" (click)="scanQRCode()" tabindex="-1"><i class="fas fa-qrcode"></i> QR-Code</button>
      </div-->
    </div>

    <video #video playsinline *ngIf="showVideo" class="w-100">

    </video>

    <canvas #canvasElement hidden *ngIf="showVideo"></canvas>

    <div class="pt-3">
      <re-captcha (resolved)="resolved($event)" siteKey="6LcnaMsZAAAAACbAEIdkhZNrgBNXaCS9rpeEF1Mq" #captcha></re-captcha>
    </div>

    <button class="btn btn-lg btn-secondary btn-block mt-3" type="submit" [disabled]="form.invalid || isLoading || !captchaToken">
      <span *ngIf="isLoading"><i class="fas fa-spin fa-spinner"></i></span>
      Login</button>
    <div class="alert alert-dangermt-3 bg-primary text-white mt-3" *ngIf="loginError">
      <i class="far fa-grimace"></i> {{loginError}}
    </div>
  </form>
</div>
