<div class="container-fluid bg-danger text-center text-white py-2 fadein" *ngIf="error">
  <p><i class="far fa-dizzy fa-3x"></i></p>
  <p>Es konnte keine Verbinung zum Server hergestellt werden. ({{error}})</p>
</div>


<div class="container" [ngSwitch]="mode">
  <h1 class="text-center mt-3"><strong>VIT Connect e.V.</strong> Mitgliederversammlung</h1>

  <div *ngSwitchCase="'quest'" class="text-center mt-5">
    <app-quest [quest]="quest" *ngIf="quest"></app-quest>
  </div>

  <div *ngSwitchCase="'result'" class="text-center mt-5">
    <app-quest-result [quest]="quest" [result]="result"></app-quest-result>
  </div>

  <div *ngSwitchCase="'wait'" class="text-center mt-5">
   <app-quest-waiting></app-quest-waiting>
  </div>

</div>
