import { Router } from '@angular/router';
import {Injectable} from '@angular/core';
import {TokenService} from './shared/services/token.service';

@Injectable()
export class AuthGuardService  {
  constructor(public tokenService: TokenService, public router: Router) {}
  canActivate(): boolean {
    if (!this.tokenService.hasToken()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
