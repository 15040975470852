import {Component, OnInit} from '@angular/core';
import {QuestService} from '../../shared/services/quest.service';
import {Quest, QuestOption} from '../../shared/models/quest.model';
import {FormsModule} from '@angular/forms';
import {NgForOf, NgIf} from '@angular/common';
import {QuestComponent} from '../../abstimmung/dashboard/quest/quest.component';
import {QuestResultComponent} from '../../abstimmung/dashboard/quest-result/quest-result.component';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
  imports: [
    FormsModule,
    NgIf,
    NgForOf,
    QuestComponent,
    QuestResultComponent
  ],
  standalone: true
})
export class AdminDashboardComponent implements OnInit {

  quest: Quest;
  result;
  mode = 'addquest';

  constructor(private questService: QuestService) {
  }

  ngOnInit() {
    this.mode = 'addquest';
    this.questService.init();
    this.quest = new Quest();
    this.quest.timeout = 60;
    this.quest.options = [];
    this.addQuestion();
    this.addQuestion();
    this.questService.onQuestResult$.subscribe(res => {
      this.result = res.results;
      this.quest = res.quest;
      this.mode = 'results';
    });
  }

  start() {
    // Generate Option-IDs
    this.quest.options.forEach((value, index) => value.id = index + 1);
    this.questService.emitNewQuest(this.quest).subscribe(value => {
    }, error => {
    });
    this.mode = 'quest';
  }

  stop() {
    this.mode = 'addquest';
    this.questService.stopQuest().subscribe();
  }

  addQuestion() {
    this.quest.options.push(new QuestOption());
  }

  removeAnswer(index: number) {
    this.quest.options.splice(index, 1);
  }

}
